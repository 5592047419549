/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";
import React from "react";
import Link from "../components/Link";
import { PrevIcon, NextIcon } from "./Icon";
import { Themed } from "theme-ui";

const Pagination = ({ numberOfPagesForCurrentLanguage, ...props }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        flexWrap: "wrap",
        fontSize: 0,
      }}
    >
      {numberOfPagesForCurrentLanguage > 1 && (
        <>
          <Box sx={{ px: 2, py: 1 }}>
            {props.context.previousPagePath ? (
              <Themed.a href={`${props.context.previousPagePath}`}>
                <PrevIcon />
              </Themed.a>
            ) : (
              <PrevIcon sx={{ color: "muted" }} />
            )}
          </Box>

          <Flex
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Array.from({ length: numberOfPagesForCurrentLanguage }, (_, i) => {
              if (props.context.pageNumber === i) {
                return (
                  <Box sx={{ px: 2, py: 1, bg: "muted" }}>
                    <Themed.strong>{i + 1}</Themed.strong>
                  </Box>
                );
              }
              return (
                <Link
                  target={
                    props.context.paginationPath +
                    `/` +
                    `${i === 0 ? `` : i + 1}`
                  }
                  sx={{
                    px: 2,
                    py: 1,
                  }}
                  key={`page${i + 1}`}
                >
                  {i + 1}
                </Link>
              );
            })}
          </Flex>
          <Box sx={{ px: 2, py: 1 }}>
            {props.context.humanPageNumber + 1 <=
            numberOfPagesForCurrentLanguage ? (
              <Themed.a href={`${props.context.nextPagePath}`}>
                <NextIcon />
              </Themed.a>
            ) : (
              <NextIcon sx={{ color: "muted" }} />
            )}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default Pagination;
