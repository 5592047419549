import { useStaticQuery, graphql } from "gatsby";

export const useAllContentfulArticles = () => {
  const { allContentfulArticle } = useStaticQuery(
    graphql`
      query allArticles {
        allContentfulArticle(
          filter: { node_locale: { ne: "en-US" }, hidden: { ne: true } }
          sort: { fields: node_locale, order: ASC }
        ) {
          edges {
            node {
              id
              contentful_id
              __typename
              node_locale
              title
              navigationTitle
              publishDate(formatString: "X")
              slug
            }
          }
        }
      }
    `
  );
  const fiArticles = allContentfulArticle.edges.filter(
    edge => edge.node.node_locale === "fi-FI"
  );
  const svArticles = allContentfulArticle.edges.filter(
    edge => edge.node.node_locale === "sv-SE"
  );

  return {
    fi: fiArticles,
    sv: svArticles,
  };
};
