/** @jsx jsx */
import { jsx, Box, Themed } from "theme-ui";
import Link from "./Link";

const ArticleCard = ({
  title,
  navigationTitle,
  publishDateInSeconds,
  slug,
  ...props
}) => {
  const publishDate = new Date(+publishDateInSeconds * 1000);
  return (
    <Box>
      <Link target={"/" + slug}>
        <Box sx={{ fontSize: 2, mb: 1 }}>
          <Themed.strong>{title}</Themed.strong>
        </Box>
        <Box sx={{ color: "text", fontSize: 0 }}>
          {publishDate.getDate()}.{publishDate.getMonth() + 1}.
          {publishDate.getFullYear()}
        </Box>
      </Link>
    </Box>
  );
};

export default ArticleCard;
