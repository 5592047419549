/** @jsx jsx */
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, jsx } from "theme-ui";
import ArticleCard from "../components/ArticleCard";
import ArticleCardList from "../components/ArticleCardList";
import BreadCrumbs from "../components/BreadCrumbs";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";
import Spacer from "../components/Spacer";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { useAllContentfulArticles } from "../hooks/useAllContentfulArticles";

const ArticlesPageTemplate = ({ data, pageContext }) => {
  const {
    pageType,
    title,
    navigationTitle,
    slug,
    parentPage,
    metaDescription,
    metaTitle,
    hideTitle,
    headerImage,
    hideBreadcrumb,
    hidden,
    canonicalTag,
  } = data.contentfulPage;

  const { language } = pageContext;
  const allArticles = useAllContentfulArticles();
  const currentLanguageArticles = allArticles[language];

  const articles = data.allContentfulArticle.edges;
  const { basePath } = pageContext;

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
    postsPerPage,
  } = useSiteMetadata();
  //TODO add humanPageNumber to page title?
  const metadataTitle =
    metaTitle || pageContext.titles[language]
      ? pageContext.titles[language]
      : defaultTitle;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  const metaImage = defaultImage;

  const paginationPages = Math.round(
    currentLanguageArticles.length / postsPerPage
  );

  return (
    <Layout pageContext={pageContext} pageType={pageType}>
      <SEO
        lang={pageContext.language}
        title={metadataTitle}
        description={metadataDescription}
        image={siteUrl + metaImage}
        paths={pageContext.paths}
        hidden={hidden}
        metadataTitle={metadataTitle}
        canonicalTag={canonicalTag}
      />
      {!hideBreadcrumb && (
        <Container>
          <BreadCrumbs
            slug={slug}
            navigationTitle={navigationTitle}
            parentPage={parentPage}
            language={language}
          />
        </Container>
      )}
      <Container variant="narrow" id="content">
        {headerImage && <GatsbyImage image={headerImage.gatsbyImageData} />}
        {!hideTitle && <PageTitle>{title}</PageTitle>}

        <ArticleCardList>
          {articles.map(({ node: article }) => (
            <ArticleCard
              key={article.id}
              publishDateInSeconds={article.publishDate}
              title={article.title}
              slug={article.slug}
              {...article}
              basePath={basePath}
            />
          ))}
        </ArticleCardList>
        <Spacer />
        <Pagination
          context={pageContext}
          numberOfPagesForCurrentLanguage={paginationPages}
        />
        <Spacer />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query (
    $id: String!
    $skip: Int!
    $limit: Int!
    $node_locale: String!
    $aspectRatio: Float
  ) {
    contentfulPage(id: { eq: $id }) {
      pageType
      title
      navigationTitle
      slug
      parentPage {
        ... on ContentfulProductPage {
          slug
          navigationTitle
        }
        ... on ContentfulPage {
          slug
          navigationTitle
        }
        ... on ContentfulThemePage {
          slug
          navigationTitle
        }
      }
      canonicalTag
      metaKeywords
      metaDescription {
        internal {
          content
        }
      }
      metaTitle
      hideTitle
      headerImage {
        gatsbyImageData(aspectRatio: $aspectRatio, quality: 85, width: 1080)
      }
      hideBreadcrumb
      hidden
    }

    allContentfulArticle(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
      filter: { node_locale: { eq: $node_locale }, hidden: { ne: true } }
    ) {
      edges {
        node {
          id
          contentful_id
          __typename
          title
          slug
          node_locale
          navigationTitle
          publishDate(formatString: "X")
          pageType
        }
      }
    }
  }
`;

export default ArticlesPageTemplate;
